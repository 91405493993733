
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { camelizeKeys } from "humps"

export default {
  name: "Live",
  async asyncData({ redirect, app, route }) {
    const cameraStore = useCameraStore()
    const routeCameraExid = camelizeKeys(route.params)?.cameraExid
    if (!useAccountStore().token && !cameraStore.selectedCamera) {
      return redirect("/v2/users/signin")
    }

    if (!app.$permissions.user.can.access.recordings()) {
      return redirect(cameraStore.cameraRoute)
    }

    const isCameraOnline =
      cameraStore.getCameraByExid(routeCameraExid)?.isOnline
    const getFormattedTimestamp = (timestamp) => {
      return app
        .$moment(timestamp)
        .tz(cameraStore.selectedCameraTimezone)
        .startOf("hour")
        .format("YYYY-MM-DDTHH:mm:ssZ")
    }

    const timestamp = getFormattedTimestamp(
      isCameraOnline
        ? new Date().toISOString()
        : cameraStore.getCameraByExid(routeCameraExid)?.lastOnlineAt
    )

    redirect(`${cameraStore.cameraRoute}/recordings/snapshots/${timestamp}`)
  },
}
